import { UseToastOptions, useToast } from "@treasured/ui";

export const useCustomToast = () => {
  const toast = useToast();

  const customToast = (options?: UseToastOptions) =>
    toast({
      isClosable: true,
      position: "top-right",
      duration: 5000,
      ...options,
    });

  return customToast;
};
