import {
  Box,
  CustomCard,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@treasured/sdk";
import { signInPage, signUpPage, userDetailsPage } from "~/pages/account";
import { Flex, HStack, Image, MenuDivider, Text } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import accountManager, {
  AccountResponseStatus,
} from "~/utils/accountService/accountManager";
import { MdExitToApp, MdArrowDropDown, MdSettings } from "react-icons/md";
import { useCustomToast } from "~/utils/accountService/messageModal";

export default function DisplayAccountStatus() {
  const router = useRouter();
  const [userSignedIn, setUserSignedIn] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const customToast = useCustomToast();

  const handleSignOut = async () => {
    const response = await accountManager.SignOut();
    if (response.status == AccountResponseStatus.SUCCESS) {
      router.push(signInPage);
    }

    customToast({
      description: response.message,
      status:
        response.status == AccountResponseStatus.SUCCESS ? "success" : "error",
    });
  };

  useEffect(() => {
    GetAccountStatus();
  }, []);

  async function GetAccountStatus() {
    const response = await accountManager.GetSession();
    if (response.status == AccountResponseStatus.SUCCESS) {
      setUserSignedIn(true);
    } else {
      setUserSignedIn(false);
    }
  }

  function DisplaySignedInUser() {
    return (
      <>
        <Flex justifyContent="flex-end" alignItems="center">
          <Menu>
            <MenuButton
              as={Flex}
              alignItems="center"
              transition="all 0.2s ease-in-out"
              _hover={{
                color: "whiteAlpha.500",
                cursor: "pointer",
                boxShadow: "dark-lg",
                transform: "scale(1.025)",
              }}
              _expanded={{
                color: "whiteAlpha.500",
                cursor: "pointer",
                boxShadow: "0 1px 0 0 white",
              }}
            >
              <HStack>
                <Image
                  boxSize="2rem"
                  borderRadius="full"
                  src="https://placekitten.com/100/100"
                  alt="Profile picture"
                />
                <Icon as={MdArrowDropDown} w={6} h={6} color="whiteAlpha.800" />
              </HStack>
            </MenuButton>

            <MenuList textColor={"white"}>
              <Box mt={"2"} ml={"3"}>
                <Text fontSize={14}>Signed in as:</Text>
                {accountManager.UserEmail}
              </Box>
              <MenuDivider />
              <MenuItem
                icon={<Icon as={MdSettings} />}
                onClick={() => {
                  router.push(userDetailsPage);
                }}
              >
                Projects
              </MenuItem>
              <MenuItem icon={<MdExitToApp />} onClick={handleSignOut}>
                Sign out
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </>
    );
  }

  function DisplayGuestUser() {
    return (
      <>
        <Flex justifyContent="flex-end" alignItems="center" gap={"4"}>
          <Box
            as="a"
            p={2}
            transition="all 0.2s ease-in-out"
            _hover={{
              boxShadow: "lg",
              transform: "scale(1.025)",
              cursor: "pointer",
            }}
            color={"white"}
            onClick={() => router.push(signInPage)}
          >
            Sign in
          </Box>
          <CustomCard
            as="a"
            variant="acrylic"
            background={"blackAlpha.500"}
            p={2}
            transition="all 0.2s ease-in-out"
            _hover={{
              boxShadow: "lg",
              transform: "scale(1.025)",
              bg: "blackAlpha.50",
              cursor: "pointer",
            }}
            role="group"
            onClick={() => router.push(signUpPage)}
          >
            Create account
          </CustomCard>
        </Flex>
      </>
    );
  }

  return <>{userSignedIn ? <DisplaySignedInUser /> : <DisplayGuestUser />}</>;
}
